<template>
  <div class="scheme-contain">
    <div class="add-box">
      <Row>
        <Col span="2" offset="22">
          <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
            <router-link class="new-doc" :to="{name: 'addDrug'}">添加信息</router-link>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="Drug" ref="table" @on-selection-change="handleSelect"></Table>

      <Row>
        <Col span="12">
          <div class="table-oprate">
            <!-- <Checkbox>全选</Checkbox> -->
            <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
        </Col>
        <Col span="12">
          <span class="records">共{{ sizeTotal }}条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="sizeTotal" :current="curPage" :page-size="sizePage" @on-change="changePage"></Page>
      </div>
    </div>

  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上传时间', key: 'create_time', align: 'center' },
				{ title: '名称', key: 'name', align: 'center' },
				{ title: '类型', key: 'type', align: 'center' },
				{ title: '使用方法', key: 'way', align: 'center' },
				{ title: '使用开始时间', key: 'start_time', align: 'center' },
				{ title: '服用依从性', key: 'compliance', align: 'center' },
				{ title: '采购地点', key: 'place', align: 'center' },
				{ title: '寄存数量', key: 'num', align: 'center' },
				{ title: '录入人', key: 'lrr', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											this.$router.push({
												name: 'detailDrug',
												params: { drug_id: this.Drug[params.index].id },
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: `确定删除 [ ${params.row.name} ] 吗`,
												onOk: () => {
													this.handleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								// h('a', {
								//   class: 'icon_edit',
								//   style: {
								//     marginRight: 0
								//   },
								//   on: {
								//     click: () => {
								//       this.$router.push({ name: 'detailDrug', params: {drug_id: this.Drug[params.index].id} })
								//     }
								//   }
								// })
							]);
						}
					},
				},
			],
			Drug: [],
			sizePage: 10,
			sizeTotal: 0,
			curPage: 1,
			params: {
				member_id: '',
				monitor_id: '',
				page: '',
				size: '',
			},
			deleteItem: {
				drug_list: [],
			},
			power: [],
		};
	},
	computed: {
		monitorId() {
			return this.$route.params.monitorId;
		},
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},

	created() {
		this.power = this.userInfo.power;
		this.params.member_id = this.memberId;
		this.params.page = this.curPage;
		this.params.size = this.sizePage;
		this.params.monitor_id = this.monitorId;
		this.queryDrugData();
	},

	methods: {
		// 查询数据
		queryDrugData() {
			MemberService.queryDrug(this.params).then((data) => {
				this.Drug = data.list;
				this.sizeTotal = data.row_size;
				this.curPage = data.cur_page;
				this.deleteItem.drug_list = [];
			});
		},

		// 跳转页面
		changePage(page) {
			this.params.page = page;
			this.queryDrugData();
		},

		// table勾选
		handleSelect(selection) {
			this.deleteItem.drug_list = [];
			selection.forEach((item) => {
				this.deleteItem.drug_list.push(item.id);
			});
		},

		// 删除
		handleRemove(index) {
			let req = [this.Drug[index].id];
			MemberService.delDrug({ id: JSON.stringify(req) }).then(() => {
				this.queryDrugData();
				this.$Message.warning('删除成功');
			});
		},

		// 删除多个成药与要素数据
		handleAllRemove() {
			if (this.deleteItem.drug_list.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选成药与要素数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},

		// 确认删除
		handleConfirmDelete() {
			MemberService.delDrug({ id: JSON.stringify(this.deleteItem.drug_list) }).then(() => {
				this.queryDrugData();
				this.deleteItem.drug_list = [];
				this.$Message.warning('删除成功');
			});
		},
	},
};
</script>
<style lang="css" scoped>
</style>
